import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
import Footer from "components/footers/FiveColumnWithInputForm.js";
import { SectionHeading } from "components/misc/Headings";

const HeadingRow = tw.div`flex`;
const Heading = tw(SectionHeading)`text-gray-900 mb-10`;
const Text = styled.div`
  ${tw`text-lg  text-gray-800`}
  p {
    ${tw`mt-2 leading-loose`}
  }
  h1 {
    ${tw`text-3xl font-bold mt-10`}
  }
  h2 {
    ${tw`text-2xl font-bold mt-8`}
  }
  h3 {
    ${tw`text-xl font-bold mt-6`}
  }
  ul {
    ${tw`list-disc list-inside`}
    li {
      ${tw`ml-2 mb-3`}
      p {
        ${tw`mt-0 inline leading-normal`}
      }
    }
  }
`;

export default ({ headingText = "Terms And Condition" }) => {
  return (
    <AnimationRevealPage>
      <Header />
      <Container>
        <ContentWithPaddingXl>
          <HeadingRow>
            <Heading>{headingText}</Heading>
          </HeadingRow>
          <Text>
            <p>Last updated: February 01, 2023</p>
            <p>
              Please read these terms and conditions carefully before using Our
              Service.
            </p>
            <h2>Terms and Conditions for Accessibly</h2>
            <p>
              1. <b>Introduction:</b> These terms and conditions govern the use
              of our web service and the agreement between you and us. By
              accessing or using our service, you agree to be bound by these
              terms and conditions.
            </p>
            <p>
              2. <b>Use of Service:</b> You may use our service only for lawful
              purposes and in accordance with these terms and conditions. You
              agree not to use our service in any way that violates any
              applicable law or regulation.
            </p>
            <p>
              3. <b>Account:</b> In order to use certain features of our
              service, you may need to create an account. You agree to provide
              accurate and complete information when creating your account, and
              to keep your account information up to date. You are responsible
              for safeguarding your account information and keeping it
              confidential.
            </p>
            <p>
              4. <b>Content:</b> Our service may allow you to submit, upload,
              publish, or otherwise make available content, including but not
              limited to text, photographs, and videos (“User Content”). You
              retain all rights in and to your User Content, and you are
              responsible for the accuracy, legality, and appropriateness of
              your User Content.
            </p>
            <p>
              5. <b>Proprietary Rights:</b> Our service and its original
              content, features, and functionality are and will remain the
              exclusive property of Accessibly and its licensors. Our service is
              protected by copyright, trademark, and other laws of both the
              [Country] and foreign countries. Our trademarks and trade dress
              may not be used in connection with any product or service without
              the prior written consent of Accessibly.
            </p>
            <p>
              6. <b>Disclaimer of Warranty:</b> Our service is provided on an
              “as is” and “as available” basis. We make no representations or
              warranties of any kind, express or implied, as to the operation of
              our service or the information, content, materials, or products
              included on our service.
            </p>
            <p>
              7. <b>Limitation of Liability:</b> In no event shall Accessibly,
              its officers, directors, employees, agents, or licensors be liable
              for any damages arising from the use of our service.
            </p>
            <p>
              8. <b>Indemnification:</b> You agree to indemnify, defend, and
              hold harmless Accessibly, its officers, directors, employees,
              agents, and licensors from and against any and all claims,
              liabilities, damages, losses, and expenses, including reasonable
              attorney's fees and costs, arising out of or in any way connected
              with your access to or use of our service.
            </p>
            <p>
              9. <b>Changes to Terms:</b> We may modify these terms and
              conditions from time to time. The revised terms will take effect
              when posted on our website.
            </p>
            <p>
              10. <b>Governing Law:</b> These terms and conditions shall be
              governed by and construed in accordance with the laws of the
              Canada. Any dispute arising under these terms and conditions shall
              be resolved exclusively in the courts of Canada.
            </p>
            Regenerate response
            <ul>
              <li>By email: support@accessibly.com</li>
              {/* <li>By phone number: 408.996.1010</li> */}
            </ul>
          </Text>
        </ContentWithPaddingXl>
      </Container>
      <Footer />
    </AnimationRevealPage>
  );
};
