import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container as ContainerBase } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import illustration from "images/signup-illustration.svg";
import logo from "images/logo.svg";
import googleIconImageSrc from "images/google-icon.png";
import twitterIconImageSrc from "images/twitter-icon.png";
import { ReactComponent as SignUpIcon } from "feather-icons/dist/icons/arrow-right.svg"; //from "feather-icons/dist/icons/user-plus.svg";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { authActions } from "../actions";
import { GET_ERRORS } from "../actions/types";
import PasswordChecklist from "react-password-checklist";

const Container = tw(
  ContainerBase
)`min-h-screen bg-primary-900 text-white font-medium flex justify-center -m-8`;
const Content = tw.div`max-w-screen-xl m-0 sm:mx-20 sm:my-16 bg-white text-gray-900 shadow sm:rounded-lg flex justify-center flex-1`;
const MainContainer = tw.div`lg:w-1/2 xl:w-5/12 p-6 sm:p-12`;
const LogoLink = tw.a``;
const LogoImage = tw.img`h-12 mx-auto`;
const MainContent = tw.div`mt-12 flex flex-col items-center`;
const Heading = tw.h1`text-2xl xl:text-3xl font-extrabold`;
const FormContainer = tw.div`w-full flex-1 mt-8`;

const SocialButtonsContainer = tw.div`flex flex-col items-center`;
const SocialButton = styled.a`
  ${tw`w-full max-w-xs font-semibold rounded-lg py-3 border text-gray-900 bg-gray-100 hocus:bg-gray-200 hocus:border-gray-400 flex items-center justify-center transition-all duration-300 focus:outline-none focus:shadow-outline text-sm mt-5 first:mt-0`}
  .iconContainer {
    ${tw`bg-white p-2 rounded-full`}
  }
  .icon {
    ${tw`w-4`}
  }
  .text {
    ${tw`ml-4`}
  }
`;

const DividerTextContainer = tw.div`my-12 border-b text-center relative`;
const DividerText = tw.div`leading-none px-2 inline-block text-sm text-gray-600 tracking-wide font-medium bg-white transform -translate-y-1/2 absolute inset-x-0 top-1/2 bg-transparent`;

const Form = tw.form`mx-auto max-w-xs flex flex-col gap-y-4`;
const Input = tw.input`w-full px-8 py-4 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-lg focus:outline-none focus:border-gray-400 focus:bg-white mt-8 first:mt-0`;
const Label = tw.label`first:mt-5`;
const SubmitButton = styled.button`
  ${tw`mt-5 tracking-wide font-semibold bg-primary-500  hover:bg-primary-900 text-gray-100 w-full py-4 rounded-lg transition-all duration-300 ease-in-out flex items-center justify-center focus:shadow-outline focus:outline-none`}
  .icon {
    ${tw`w-6 h-6 -mr-2`}
  }
  .text {
    ${tw`mr-3`}
  }
`;
const IllustrationContainer = tw.div`sm:rounded-r-lg flex-1 bg-purple-100 text-center hidden  justify-center`; //lg:flex
const IllustrationImage = styled.div`
  ${(props) => `background-image: url("${props.imageSrc}");`}
  ${tw`m-12 xl:m-16 w-full max-w-lg bg-contain bg-center bg-no-repeat`}
`;
const ErrorDiv = tw.div`text-red-500 text-center font-medium`;

export default ({
  logoLinkUrl = "#",
  illustrationImageSrc = illustration,
  headingText = "Sign Up For Accessibly",
  socialButtons = [
    {
      iconImageSrc: googleIconImageSrc,
      text: "Sign Up With Google",
      url: "https://google.com",
    },
    {
      iconImageSrc: twitterIconImageSrc,
      text: "Sign Up With Twitter",
      url: "https://twitter.com",
    },
  ],
  submitButtonText = "Next",
  SubmitButtonIcon = SignUpIcon,
  tosUrl = "/terms",
  privacyPolicyUrl = "/privacy-policy",
  signInUrl = "/login",
}) => {
  var error = useSelector((state) => state.errors.signup);
  // console.log("error", error);

  const dispatch = useDispatch();
  const { registerLoginUser, emptyErrors } = bindActionCreators(
    authActions,
    dispatch
  );
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [pswdOk, setPswdOk] = useState(false);
  const [companyName, setCompanyName] = useState("");
  const [websiteUrl, setWebsiteUrl] = useState("");
  const [pswdMatch, setPswdMatch] = useState(false);
  const history = useHistory();

  useEffect(() => {
    if (password === passwordConfirm) {
      setPswdMatch(true);
    }
  }, [passwordConfirm]);

  function onSubmit(e) {
    console.log("event", e, password);

    e.preventDefault();
    emptyErrors();
    if (password !== passwordConfirm) {
      console.log("passwordConfirm", passwordConfirm);
      setPswdMatch(false);

      // dispatch({
      //   type: GET_ERRORS,
      //   payload: {
      //     signup: e,
      //   },
      // });
    } else if (!pswdOk || !pswdMatch) {
      console.log("pswdOk", pswdOk);

      // dispatch({
      //   type: GET_ERRORS,
      //   payload: {
      //     signup: {
      //       password: { message: "Password does not match conditions" },
      //     },
      //   },
      // });
    } else {
      const newUser = {
        email: email,
        password: password,
        passwordConfirm: passwordConfirm,
        companyName: companyName,
        websiteUrl: websiteUrl,
      };
      console.log("newUser", newUser);
      registerLoginUser(newUser);
    }

    //error = null;
    // console.log("onnext");
  }

  return (
    <AnimationRevealPage>
      <Container>
        <Content>
          <MainContainer>
            <LogoLink href={logoLinkUrl}>
              <LogoImage src={logo} />
            </LogoLink>
            <MainContent>
              <Heading>{headingText}</Heading>
              <FormContainer>
                {/* <SocialButtonsContainer>
                {socialButtons.map((socialButton, index) => (
                  <SocialButton key={index} href={socialButton.url}>
                    <span className="iconContainer">
                      <img
                        src={socialButton.iconImageSrc}
                        className="icon"
                        alt=""
                      />
                    </span>
                    <span className="text">{socialButton.text}</span>
                  </SocialButton>
                ))}
              </SocialButtonsContainer> */}
                {/* <DividerTextContainer>
                <DividerText>Or Sign up with your e-mail</DividerText>
              </DividerTextContainer> */}
                <Form role="form" onSubmit={onSubmit}>
                  <Label>
                    Email
                    <Input
                      type="email"
                      placeholder="Email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      required
                    />
                  </Label>
                  {error && error.email && (
                    <ErrorDiv>{error.email.message}</ErrorDiv>
                  )}
                  <Label>
                    Password
                    <Input
                      type="password"
                      placeholder="Password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      required
                    />
                  </Label>
                  {error && error.password && (
                    <ErrorDiv>{error.password.message}</ErrorDiv>
                  )}
                  <Label>
                    Confirm Password
                    <Input
                      type="password"
                      placeholder="Confirm Password"
                      value={passwordConfirm}
                      onChange={(e) => {
                        setPasswordConfirm(e.target.value);
                        // if (password !== passwordConfirm) {
                        //   setPswdMatch(false);
                        // }
                      }}
                      required
                    />
                  </Label>
                  {error && error.passwordConfirm && (
                    <ErrorDiv>{error.passwordConfirm.message}</ErrorDiv>
                  )}
                  <Label>
                    Company Name
                    <Input
                      type="text"
                      placeholder="Company Name"
                      value={companyName}
                      onChange={(e) => setCompanyName(e.target.value)}
                      required
                    />
                  </Label>
                  {error && error.companyName && (
                    <ErrorDiv>{error.companyName.message}</ErrorDiv>
                  )}
                  <Label>
                    Company Website URL
                    <Input
                      type="text"
                      placeholder="eg:https://www.google.com/"
                      value={websiteUrl}
                      onChange={(e) => setWebsiteUrl(e.target.value)}
                      required
                    />
                  </Label>

                  <PasswordChecklist
                    rules={["minLength", "specialChar", "number", "capital"]}
                    minLength={6}
                    value={password}
                    onChange={(isValid) => {
                      setPswdOk(isValid);
                    }}
                  />
                  {error && error.websiteUrl && (
                    <ErrorDiv>{error.websiteUrl.message}</ErrorDiv>
                  )}
                  {!pswdOk && (
                    <ErrorDiv>Password does not match conditions.</ErrorDiv>
                  )}
                  {!pswdMatch && <ErrorDiv>Passwords don't match.</ErrorDiv>}
                  {/* {error && <ErrorDiv>{error}</ErrorDiv>} */}
                  <SubmitButton type="submit">
                    <span className="text">{submitButtonText}</span>
                    <SubmitButtonIcon className="icon" />
                  </SubmitButton>
                  <p tw="mt-6 text-xs text-gray-600 text-center">
                    I agree to abide by Accessibly's{" "}
                    <a
                      href={tosUrl}
                      tw="border-b border-gray-500 border-dotted"
                    >
                      Terms of Service
                    </a>{" "}
                    and its{" "}
                    <a
                      href={privacyPolicyUrl}
                      tw="border-b border-gray-500 border-dotted"
                    >
                      Privacy Policy
                    </a>
                  </p>

                  <p tw="mt-8 text-sm text-gray-600 text-center">
                    Already have an account?{" "}
                    <a
                      href={signInUrl}
                      tw="border-b border-gray-500 border-dotted"
                    >
                      Sign In
                    </a>
                  </p>
                </Form>
              </FormContainer>
            </MainContent>
          </MainContainer>
          <IllustrationContainer>
            <IllustrationImage imageSrc={illustrationImageSrc} />
          </IllustrationContainer>
        </Content>
      </Container>
    </AnimationRevealPage>
  );
};
