import React from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Hero from "components/hero/TwoColumnWithInput.js";
import Features from "components/features/ThreeColWithSideImage.js";
import MainFeature from "components/features/TwoColWithButton.js";
import MainFeature2 from "components/features/TwoColWithTwoHorizontalFeaturesAndButton.js";
import FeatureWithSteps from "components/features/TwoColWithSteps.js";
import Pricing from "components/pricing/ThreePlans.js";
import Testimonial from "components/testimonials/TwoColumnWithImageAndRating.js";
import FAQ from "components/faqs/SingleCol.js";
import GetStarted from "components/cta/GetStarted";
import Footer from "components/footers/FiveColumnWithBackground.js";
import heroScreenshotImageSrc from "images/hero-screenshot-1.png";
import macHeroScreenshotImageSrc from "images/hero-screenshot-new.png";
import prototypeIllustrationImageSrc from "images/prototype-illustration.svg";
import { ReactComponent as BriefcaseIcon } from "feather-icons/dist/icons/briefcase.svg";
import { ReactComponent as MoneyIcon } from "feather-icons/dist/icons/dollar-sign.svg";

export default () => {
  const Subheading = tw.span`uppercase tracking-widest font-bold text-primary-500`;
  const HighlightedText = tw.span`text-primary-500`;

  return (
    <AnimationRevealPage>
      <Hero roundedHeaderButton={false} />
      <Features
        subheading={<Subheading>Features</Subheading>}
        heading={
          <>
            We make your website <HighlightedText>Accessible</HighlightedText>{" "}
            for Everyone<HighlightedText> with ZERO cost</HighlightedText>
          </>
        }
      />
      {/* <MainFeature
        subheading={<Subheading>Quality Work</Subheading>}
        imageSrc={heroScreenshotImageSrc}
        imageBorder={true}
        imageDecoratorBlob={true}
      /> */}
      <FeatureWithSteps
        subheading={<Subheading>STEPS</Subheading>}
        heading={
          <>
            Easy to <HighlightedText>Get Started.</HighlightedText>
          </>
        }
        textOnLeft={false}
        imageSrc={macHeroScreenshotImageSrc}
        imageDecoratorBlob={true}
        decoratorBlobCss={tw`xl:w-40 xl:h-40 opacity-15 -translate-x-1/2 left-1/2`}
      />
      {/* <MainFeature2
        subheading={<Subheading>VALUES</Subheading>}
        heading={
          <>
            We Always Abide by Our{" "}
            <HighlightedText>Principles.</HighlightedText>
          </>
        }
        imageSrc={prototypeIllustrationImageSrc}
        showDecoratorBlob={false}
        features={[
          {
            Icon: MoneyIcon,
            title: "Affordable",
            description:
              "We promise to offer you the best rate we can - at par with the industry standard.",
            iconContainerCss: tw`bg-green-300 text-green-800`,
          },
          {
            Icon: BriefcaseIcon,
            title: "Professionalism",
            description:
              "We assure you that our templates are designed and created by professional designers.",
            iconContainerCss: tw`bg-red-300 text-red-800`,
          },
        ]}
      /> */}
      {/* <Pricing
        subheading={<Subheading>Pricing</Subheading>}
        heading={
          <>
            Reasonable & Flexible <HighlightedText>Plans.</HighlightedText>
          </>
        }
        plans={[
          {
            name: "Monthly",
            price: "$30",
            duration: "Monthly",
            mainFeature: "For Individuals",
            features: [
              "30 Templates",
              "7 Landing Pages",
              "12 Internal Pages",
              "Basic Assistance",
            ],
          },
          {
            name: "Business",
            price: "$37.99",
            duration: "Monthly",
            mainFeature: "For Small Businesses",
            features: [
              "60 Templates",
              "15 Landing Pages",
              "22 Internal Pages",
              "Priority Assistance",
            ],
            featured: true,
          },
          {
            name: "Enterprise",
            price: "$57.99",
            duration: "Monthly",
            mainFeature: "For Large Companies",
            features: [
              "90 Templates",
              "27 Landing Pages",
              "37 Internal Pages",
              "Personal Assistance",
            ],
          },
        ]}
      /> */}
      <Testimonial
        subheading={<Subheading>Testimonials</Subheading>}
        heading={
          <>
            Our Clients <HighlightedText>Love Us.</HighlightedText>
          </>
        }
        testimonials={[
          {
            stars: 5,
            profileImageSrc: "https://i.ibb.co/09QnQ1p/logo.png",
            heading: "Falcon Driving",
            quote:
              "Accessibly helped us making our online services accessible to wide range of customers, especially to senior citizens and users with reading difficulties. ",
            customerName: "M. Khan",
            customerTitle: "CEO, Falcon Driving",
          },
          // {
          //   stars: 5,
          //   profileImageSrc:
          //     "https://images.unsplash.com/photo-1531427186611-ecfd6d936c79?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2.25&w=512&h=512&q=80",
          //   heading: "Love the Developer Experience and Design Principles !",
          //   quote:
          //     "Sinor Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.",
          //   customerName: "Adam Cuppy",
          //   customerTitle: "Founder, EventsNYC",
          // },
        ]}
      />
      <FAQ
        subheading={<Subheading>FAQS</Subheading>}
        heading={
          <>
            You have <HighlightedText>Questions ?</HighlightedText>
          </>
        }
        faqs={[
          {
            question: "Is Accessibly free of Cost?",
            answer:
              "Yes, currently Accessibly is available without any cost for everyone. You just have to login and use the code in your website. ",
          },
          {
            question: "What is Web Accessibility?",
            answer:
              "Web accessibility means that websites, tools, and technologies are designed and developed so that people with disabilities can use them. More specifically, people can: \n a) perceive, understand, navigate, and interact with the Web \n b) contribute to the Web",
          },
          {
            question: "Why should your website be accessible? ",
            answer:
              " It is important that the Web be accessible to everyone in order to provide equal access and equal opportunity to people with disabilities. about 15 percent of the world's population experience some form of disability, according to The Internet Society Accessibility Special Interest Group. An accessible Web can help people with disabilities participate more actively in society. Many counties like Canada and the US are enforcing we accessibility by law too.  ",
          },
          {
            question: "How does the accessibility is measured and regulated?",
            answer:
              "Accessibility of a website is assessed towards the standards set by W3C (World Wide Web Consortium) which is called WCAG. Web Content Accessibility Guidelines (WCAG) 2.1 defines how to make Web content more accessible to people with disabilities. Accessibility involves a wide range of disabilities, including visual, auditory, physical, speech, cognitive, language, learning, and neurological disabilities.WCAG guidelines are categorized into three levels of conformance in order to meet the needs of different groups and different situations: A (lowest), AA (mid range), and AAA (highest). Accessibility is also supported and encouraged by law, Americans with Disabilities Act (ADA) sets guidelines to a website to be accessible.",
          },

          {
            question:
              "Will Accessibly make my website WCAG 2.1 & ADA complaint?",
            answer:
              "No. Even though Accessibly contributes immensly towards making your website WCAG and ADA complaint,  we do not make the website 100% accessible because we do not cover the modifications that should be made in basic code of a website. ",
          },
          {
            question:
              "What does Accessibly NOT offering towards WCAG 2.1 & ADA compliance ?",
            answer:
              "Accessibly mainly enhances the distinguishability and adaptability of the content. We do not cover other WCAG-characteristics like Keyboard accessibility, navigability etc. because these demand the source code access.",
          },
          {
            question:
              "Does Accessibly collect information about visitors on your website?",
            answer:
              "Accessibly does not collect any personally identifiable information (PII) or behavioral data on your site or its visitors.",
          },
        ]}
      />
      <GetStarted />
      <Footer />
    </AnimationRevealPage>
  );
};
