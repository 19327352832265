import React, { useEffect } from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Header from "components/headers/light.js";
import Pricing from "components/pricing/TwoPlansWithDurationSwitcher.js";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";

export default () => {
  var pricingSituation = {
    actionHeading: "Select",
    actionLink: "/codeSnippet",
    freeActionLink: "/codeSnippet",
    monthlyPriceId: "price_1MVdOFGT9VE2tkxVePjWjdOP", // TODO: Generate stripe links
    yearlyPriceId: "price_1MVdOFGT9VE2tkxV3FB090Gm", // https://buy.stripe.com/test_fZe14v4QPfUz9QA8ww
  };

  const history = useHistory();

  // var userId = useSelector((state) => state.auth.user_data);
  // useEffect(() => {
  //  if(userId){

  //  }
  // }, [userId]);
  // window.onpopstate = (e) => {
  //   history.push("/customize");
  //   // Swal.fire({
  //   //   title: "Are you sure?",
  //   //   text: "You can try it free for 1 month!",
  //   //   icon: "warning",
  //   //   showCancelButton: true,
  //   //   confirmButtonColor: "#6314ff",
  //   //   cancelButtonColor: "#d33",
  //   //   confirmButtonText: "Continue ",
  //   // }).then((result) => {
  //   //   if (result.isConfirmed) {
  //   //     history.push("/customize");
  //   //   }
  //   // });
  // };

  return (
    <AnimationRevealPage>
      <Pricing situation={pricingSituation} />
    </AnimationRevealPage>
  );
};
