import React from "react";
import { useEffect, useState } from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Hero from "components/hero/TwoColumnWithInput.js";
import Header from "components/headers/light.js";
import GetStartedLight from "components/cta/GetStartedLight";
import Features from "components/features/ThreeColWithSideImage.js";
import Footer from "components/footers/FiveColumnWithBackground.js";
import DesignIllustration from "../images/no-data.svg";
import { ResponsiveLine } from "@nivo/line";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { authActions } from "../actions";
import { useHistory } from "react-router-dom";
import moment from "moment";
import { subscriptionActions } from "actions";

export default () => {
  const Subheading = tw.span`uppercase tracking-widest font-bold text-primary-500`;
  const HighlightedText = tw.span`text-primary-500`;
  const LineChart = tw.div`flex h-128 w-full`;
  const HeaderDiv = tw.div`mt-4 mb-4`;
  const Heading = tw.h1`font-bold text-3xl md:text-3xl lg:text-4xl xl:text-5xl text-gray-900 leading-tight`;
  const Container = tw.div`w-full h-full m-6`;
  const Column = tw.div`relative mt-12 mb-12 lg:w-8/12 text-center max-w-lg mx-auto lg:max-w-none lg:text-left`;

  const dispatch = useDispatch();
  const history = useHistory();
  const { getVisit, getUser } = bindActionCreators(authActions, dispatch);
  const { createBillingPortal } = bindActionCreators(
    subscriptionActions,
    dispatch
  );

  var userId = useSelector((state) => state.auth.user_data);
  var vc = useSelector((state) => state.auth.visitCount);

  const [noUser, setNoUser] = useState(true);

  window.onpopstate = () => {
    history.push("/");
  };
  const handlePrimaryLink = () => {
    console.log("clicked", userId._id);
    createBillingPortal({ userId: userId._id });
  };

  useEffect(() => {
    console.log("in getVisit", userId);
    if (userId && noUser) {
      getVisit({ _id: userId._id });
      getUser(userId._id);
      console.log(" userId._id", userId._id);
      setNoUser(false);
    }
  }, [userId]);

  // useEffect(() => {
  //   console.log("in getVisit", userId);
  //   if (vc) {

  //   }
  // }, [vc]);

  function getDateWithoutTime(d) {
    var date = d.getFullYear() + "-" + (d.getMonth() + 1) + "-" + d.getDate();
    return date;
  }
  const getRemainingDays = (expDate) => {
    var diffInDays = moment(expDate).diff(moment(moment()), "days");
    if (diffInDays > 0) {
      return diffInDays + " days remaining";
    } else {
      return "Subscription Expired!";
    }
  };
  const formatData = (dbData) => {
    console.log("dbData", dbData);
    var newDat = [];
    var t = {};
    dbData.map((ob) => {
      t.x = getDateWithoutTime(new Date(ob.updateDate));
      t.y = ob.updateCount;
      newDat.push(t);
    });
    var resDat = [
      {
        id: "Widget Usage",
        data: newDat,
      },
    ];
    return resDat;
  };

  var lineData = [
    {
      id: "Widget Usage",
      data: [
        { x: "2018-01-01", y: 7 },
        { x: "2018-01-02", y: 5 },
        { x: "2018-01-03", y: 11 },
        { x: "2018-01-04", y: 9 },
        { x: "2018-01-05", y: 12 },
        { x: "2018-01-06", y: 16 },
        { x: "2018-01-07", y: 13 },
      ],
    },
  ];
  const VisitLineChart = (data) => (
    <ResponsiveLine
      data={data}
      margin={{ top: 30, right: 50, bottom: 60, left: 50 }}
      xScale={{
        type: "time",
        format: "%Y-%m-%d",
        useUTC: false,
        precision: "day",
      }}
      xFormat="time:%Y-%m-%d"
      yScale={{
        type: "linear",
        min: "auto",
        max: "auto",
        stacked: true,
        reverse: false,
      }}
      colors={{ scheme: "dark2" }}
      enableGridX={true}
      enablePoints={true}
      yFormat=" >-.2f"
      axisTop={null}
      axisRight={null}
      axisBottom={null}
      // axisBottom={{
      //   orient: "bottom",
      //   tickSize: 5,
      //   tickPadding: 5,
      //   tickRotation: 0,
      //   legend: "transportation",
      //   legendOffset: 36,
      //   legendPosition: "middle",
      // }}
      axisLeft={{
        orient: "left",
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        //   legend: "count",
        legendOffset: -40,
        legendPosition: "middle",
      }}
      pointSize={10}
      pointColor={{ theme: "background" }}
      pointBorderWidth={2}
      pointBorderColor={{ from: "serieColor" }}
      pointLabelYOffset={-12}
      useMesh={true}
      legends={[
        {
          anchor: "bottom",
          direction: "row",
          justify: false,
          translateX: 0,
          translateY: 40,
          itemsSpacing: 0,
          itemDirection: "left-to-right",
          itemWidth: 60,
          itemHeight: 20,
          itemOpacity: 0.75,
          symbolSize: 10,
          symbolShape: "circle",
          symbolBorderColor: "rgba(0, 0, 0, .5)",
          effects: [
            {
              on: "hover",
              style: {
                itemBackground: "rgba(0, 0, 0, .03)",
                itemOpacity: 1,
              },
            },
          ],
        },
      ]}
    />
  );

  return (
    <>
      <Container>
        <HeaderDiv>
          <Header db={true} />
        </HeaderDiv>
        <GetStartedLight
          subheading={"Welcome " + (userId ? userId.companyName : "")}
          heading={userId ? userId.email : ""}
          // heading2={userId ? getRemainingDays(userId.tokenExpiryDate) : "0"}
          handlePrimaryLink={handlePrimaryLink}
        />
        <Column>
          <Heading>Widget Usage</Heading>
          {vc && vc.length > 0 ? (
            <LineChart>{VisitLineChart(formatData(vc))}</LineChart>
          ) : (
            <>
              <img
                tw="min-w-0 w-64 h-64 mt-12 max-w-lg xl:max-w-3xl "
                src={DesignIllustration}
                alt="Design Illustration"
              />
              <p tw="font-bold text-xl">No Data Available</p>
            </>
          )}
          {/* <LineChart>{VisitLineChart(lineData)}</LineChart> */}
          {/* {spinner} */}
        </Column>

        <Footer />
      </Container>
    </>
  );
};
