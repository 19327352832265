import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container as ContainerBase } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import illustration from "images/signup-illustration.svg";
import logo from "images/logo.svg";
import googleIconImageSrc from "images/google-icon.png";
import twitterIconImageSrc from "images/twitter-icon.png";
import { ReactComponent as SignUpIcon } from "feather-icons/dist/icons/check-square.svg";
import { ReactComponent as CopyIcon } from "feather-icons/dist/icons/copy.svg"; //from "feather-icons/dist/icons/user-plus.svg";
import { BlockPicker } from "react-color";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { authActions } from "../actions";
import Swal from "sweetalert2";

const Container = tw(
  ContainerBase
)`min-h-screen bg-primary-900 text-white font-medium flex justify-center -m-8`;
const Content = tw.div`max-w-screen-xl m-0 sm:mx-20 sm:my-16 bg-white text-gray-900 shadow sm:rounded-lg flex justify-center flex-1`;
const MainContainer = tw.div`lg:w-1/2 xl:w-5/12 p-6 sm:p-12`;
const LogoLink = tw.a``;
const LogoImage = tw.img`h-12 mx-auto`;
const MainContent = tw.div`mt-12 flex flex-col items-center`;
const Heading = tw.h1`text-2xl xl:text-3xl font-extrabold`;
const FormContainer = tw.div`w-full flex-1 mt-8`;

const SocialButtonsContainer = tw.div`flex flex-col items-center`;
const SocialButton = styled.a`
  ${tw`w-full max-w-xs font-semibold rounded-lg py-3 border text-gray-900 bg-gray-100 hocus:bg-gray-200 hocus:border-gray-400 flex items-center justify-center transition-all duration-300 focus:outline-none focus:shadow-outline text-sm mt-5 first:mt-0`}
  .iconContainer {
    ${tw`bg-white p-2 rounded-full`}
  }
  .icon {
    ${tw`w-4`}
  }
  .text {
    ${tw`ml-4`}
  }
`;

const DividerTextContainer = tw.div`my-12 border-b text-center relative`;
const DividerText = tw.div`leading-none px-2 inline-block text-sm text-gray-600 tracking-wide font-medium bg-white transform -translate-y-1/2 absolute inset-x-0 top-1/2 bg-transparent`;

const Form = tw.form`mx-auto max-w-xs  `;
const Input = tw.input`w-full px-8 py-4 rounded-lg font-medium bg-gray-100 border border-gray-200 placeholder-gray-500 text-lg focus:outline-none focus:border-gray-400 focus:bg-white mt-8 first:mt-0`;
const Label = tw.label`first:mt-5`;
const TextArea = tw.textarea`block p-2 w-full h-64 text-sm text-primary-900 bg-gray-lightest rounded-lg border border-primary-300 focus:ring-blue-500 focus:border-blue-500 `;
const SubmitButton = styled.button`
  ${tw`mt-5 mb-5 tracking-wide font-semibold bg-primary-500 text-gray-100 w-full py-4 rounded-lg hover:bg-primary-900 transition-all duration-300 ease-in-out flex items-center justify-center focus:shadow-outline focus:outline-none`}
  .icon {
    ${tw`w-6 h-6 -mr-2`}
  }
  .text {
    ${tw`mr-3`}
  }
`;
// const CopyButton = styled.button`
//   ${tw.button`mt-5 mb-5 tracking-wide font-semibold bg-gray-lightest border border-primary-300 text-primary-900 w-full py-4 rounded-lg hover:bg-primary-900 hover:text-gray-lightest transition-all duration-300 ease-in-out flex items-center justify-center focus:shadow-outline focus:outline-none`}
//   .icon {
//     ${tw`w-6 h-6 -mr-2`}
//   }
//   .text {
//     ${tw`mr-3`}
//   }
// `;
const CopyButton = tw.button`mt-5 mb-5 tracking-wide font-semibold bg-gray-lightest border border-primary-300 text-primary-900 w-full py-4 rounded-lg hover:bg-primary-500 hover:text-gray-lightest transition-all duration-300 ease-in-out flex items-center justify-center focus:shadow-outline focus:outline-none`;
const IllustrationContainer = tw.div`sm:rounded-r-lg flex-1 bg-purple-100 text-center hidden  justify-center`; //lg:flex
const IllustrationImage = styled.div`
  ${(props) => `background-image: url("${props.imageSrc}");`}
  ${tw`m-12 xl:m-16 w-full max-w-lg bg-contain bg-center bg-no-repeat`}
`;

const positionOptions = [
  "Right Bottom",
  "Left Bottom",
  "Left Top",
  "Right Top",
];
export default (
  props,
  {
    logoLinkUrl = "#",
    illustrationImageSrc = illustration,
    headingText = "Customize Accessibly",

    submitButtonText = "Get Embeddable Code",
    SubmitButtonIcon = SignUpIcon,
    CopyButtonIcon = CopyIcon,

    tosUrl = "/terms",
    privacyPolicyUrl = "/privacy-policy",
    signInUrl = "/login",
  }
) => {
  var user = useSelector((state) => state.auth.user_data);
  var codeSnippet = useSelector((state) => state.auth.codeSnippet);
  const dispatch = useDispatch();
  const { patchUser } = bindActionCreators(authActions, dispatch);

  const history = useHistory();
  const [widgetColor, setWidgetColor] = useState({
    hex: "#6314ff",
    rgb: { r: 99, g: 20, b: 255, a: 1 },
  });
  const [widgetPosition, setWidgetPosition] = useState("0");
  var ed = null;
  const search = useLocation().search;
  ed = new URLSearchParams(search).get("edit");

  window.onpopstate = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "Keep Going! You can try it free for 1 month!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#6314ff",
      cancelButtonColor: "#d33",
      confirmButtonText: "Continue Customizing",
    }).then((result) => {
      if (result.isConfirmed) {
        history.push("/customize");
      }
    });
  };

  useEffect(() => {
    console.log("is codesnippet generated?", codeSnippet);
    if (codeSnippet) {
      ed === "1"
        ? history.push(
            "/codeSnippet?session_id=hdgfjsdhAAaksjSShfgss2324dk2kOK&GtfLI9o8" //TODO:Change this adjustment
          )
        : history.push("/codeSnippet"); //history.push("/selectPlan");
    }
  }, [codeSnippet]);

  const onSubmit = (e) => {
    e.preventDefault();
    var c = widgetColor.rgb;
    var wColor = "rgb(" + c.r + "," + c.g + "," + c.b + "," + c.a + ")";

    const userUpates = {
      _id: user._id,
      widgetColor: wColor,
      widgetPosition: widgetPosition.toString(),
    };
    patchUser(userUpates);
    //history.push("/");
  };
  const handleDropdwon = (e) => {
    console.log("e.target.selectedIndex", e.target.selectedIndex);
    setWidgetPosition(e.target.selectedIndex);
  };

  return (
    <AnimationRevealPage>
      <Container>
        <Content>
          <MainContainer>
            <LogoLink href={logoLinkUrl}>
              <LogoImage src={logo} />
            </LogoLink>
            <MainContent>
              <Heading>{headingText}</Heading>
              <FormContainer>
                {/* <SocialButtonsContainer>
                {socialButtons.map((socialButton, index) => (
                  <SocialButton key={index} href={socialButton.url}>
                    <span className="iconContainer">
                      <img
                        src={socialButton.iconImageSrc}
                        className="icon"
                        alt=""
                      />
                    </span>
                    <span className="text">{socialButton.text}</span>
                  </SocialButton>
                ))}
              </SocialButtonsContainer> */}
                {/* <DividerTextContainer>
                <DividerText>Or Sign up with your e-mail</DividerText>
              </DividerTextContainer> */}
                <Form>
                  <Label>
                    Widget Color
                    <BlockPicker
                      tw="mt-8"
                      color={widgetColor}
                      onChange={(color) => {
                        setWidgetColor(color);
                        console.log("color", color);
                      }}
                    />
                  </Label>
                  <Label tw="flex flex-col gap-y-2">
                    Widget Position
                    <select
                      value={positionOptions[widgetPosition]}
                      onChange={handleDropdwon}
                    >
                      {positionOptions.map((value) => (
                        <option value={value} key={value}>
                          {value}
                        </option>
                      ))}
                    </select>
                  </Label>

                  <SubmitButton type="submit" onClick={onSubmit}>
                    <span className="text">{submitButtonText}</span>
                    <SubmitButtonIcon className="icon" />
                  </SubmitButton>

                  {/* {codeSnippet && (
                    <Label>
                      Copy the following code snippet to the Head of your
                      index.html
                      <CopyButton
                        type="button"
                        onClick={() => {
                          navigator.clipboard.writeText(codeSnippet);
                          setCodeCopied(true);
                        }}
                      >
                        {" "}
                        <span className="text">copy</span>
                        <CopyButtonIcon
                          className="icon"
                          style={{ marginLeft: "10px" }}
                        />
                      </CopyButton>
                      {codeCopied && (
                        <p tw="text-primary-500">Code copied to clipboard.</p>
                      )}
                      <TextArea readOnly>{codeSnippet}</TextArea>
                    </Label>
                  )} */}
                </Form>
              </FormContainer>
            </MainContent>
          </MainContainer>
          <IllustrationContainer>
            <IllustrationImage imageSrc={illustrationImageSrc} />
          </IllustrationContainer>
        </Content>
      </Container>
    </AnimationRevealPage>
  );
};
