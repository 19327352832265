import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
import Footer from "components/footers/FiveColumnWithInputForm.js";
import { SectionHeading } from "components/misc/Headings";

const HeadingRow = tw.div`flex`;
const Heading = tw(SectionHeading)`text-gray-900 mb-10`;
const Text = styled.div`
  ${tw`text-lg  text-gray-800`}
  p {
    ${tw`mt-2 leading-loose`}
  }
  h1 {
    ${tw`text-3xl font-bold mt-10`}
  }
  h2 {
    ${tw`text-2xl font-bold mt-8`}
  }
  h3 {
    ${tw`text-xl font-bold mt-6`}
  }
  ul {
    ${tw`list-disc list-inside`}
    li {
      ${tw`ml-2 mb-3`}
      p {
        ${tw`mt-0 inline leading-normal`}
      }
    }
  }
`;
export default ({
  headingText = "General Data Protection Regulation (GDPR)",
}) => {
  return (
    <AnimationRevealPage>
      <Header />
      <Container>
        <ContentWithPaddingXl>
          <HeadingRow>
            <Heading>{headingText}</Heading>
          </HeadingRow>
          <Text>
            <p>Last updated: February 01, 2023</p>

            <p>
              <b>Introduction:</b> Accessibly, a company based in Canada, is
              committed to protecting the privacy and personal data of its
              users. This policy outlines our obligations and responsibilities
              under the General Data Protection Regulation (GDPR).
            </p>
            <h2>1. Collection and Processing of Personal Data</h2>
            <p>
              <b>Purpose:</b> We collect and process personal data for the
              following purposes:
            </p>
            <ul>
              <li>To provide our web service to our users</li>
              <li>To communicate with our users about our web service</li>
              <li>To improve our web service and user experience</li>
            </ul>
            <p>
              <b>Legal Basis:</b> The legal basis for collecting and processing
              personal data is our legitimate interests in providing our web
              service and improving it over time.
            </p>
            <h2>2. Types of Personal Data</h2>
            <p>We collect the following types of personal data:</p>
            <ul>
              <li>
                Contact information, such as name, email address, and telephone
                number
              </li>
              <li>
                Information provided through our web service, such as
                accessibility needs and preferences
              </li>
              <li>
                Usage information, such as website log data and IP address
              </li>
            </ul>
            <h2>3. Data Retention</h2>
            <p>
              We will retain personal data for as long as necessary to fulfill
              the purposes for which it was collected and processed, or as
              required by applicable laws and regulations.
            </p>
            <h2>4. Data Security</h2>
            <p>
              We take reasonable and appropriate measures to protect personal
              data from unauthorized access, disclosure, alteration, or
              destruction. This includes implementing appropriate technical and
              organizational measures, such as encryption and access controls.
            </p>
            <h2>5. Data Transfers</h2>
            <p>
              We may transfer personal data to third-party service providers
              located in countries outside of Canada, including countries that
              do not have data protection laws equivalent to those in Canada. In
              such cases, we will take appropriate measures to ensure that
              personal data is protected in accordance with this policy and
              applicable laws, such as signing standard contractual clauses or
              relying on approved certification mechanisms.
            </p>
            <h2>6. User Rights</h2>
            <p>
              Under the GDPR, individuals have the following rights with respect
              to their personal data:
            </p>
            <ul>
              <li>The right to access their personal data</li>
              <li>
                The right to rectify their personal data if it is inaccurate or
                incomplete
              </li>
              <li>
                The right to erase their personal data in certain circumstances
              </li>
              <li>
                The right to restrict or object to the processing of their
                personal data
              </li>
              <li>
                The right to data portability, which allows individuals to
                receive their personal data in a structured, commonly used, and
                machine-readable format
              </li>
            </ul>
            <p>
              To exercise any of these rights, please contact us at
              support@accessibly.com. We will respond to your request as soon as
              possible and in accordance with applicable laws.
            </p>
            <h2>7. Changes to This Policy</h2>
            <p>
              We may modify this policy from time to time. The revised policy
              will take effect when posted on our website.
            </p>
          </Text>
        </ContentWithPaddingXl>
      </Container>
      <Footer />
    </AnimationRevealPage>
  );
};
