import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
import Footer from "components/footers/FiveColumnWithInputForm.js";
import { SectionHeading } from "components/misc/Headings";

const HeadingRow = tw.div`flex`;
const Heading = tw(SectionHeading)`text-gray-900 mb-10`;
const Text = styled.div`
  ${tw`text-lg  text-gray-800`}
  p {
    ${tw`mt-2 leading-loose`}
  }
  h1 {
    ${tw`text-3xl font-bold mt-10`}
  }
  h2 {
    ${tw`text-2xl font-bold mt-8`}
  }
  h3 {
    ${tw`text-xl font-bold mt-6`}
  }
  ul {
    ${tw`list-disc list-inside`}
    li {
      ${tw`ml-2 mb-3`}
      p {
        ${tw`mt-0 inline leading-normal`}
      }
    }
  }
`;
export default ({ headingText = "Disclaimer" }) => {
  return (
    <AnimationRevealPage>
      <Header />
      <Container>
        <ContentWithPaddingXl>
          <HeadingRow>
            <Heading>{headingText}</Heading>
          </HeadingRow>
          <Text>
            <p>Last updated: February 01, 2023</p>

            <p>
              1. <b>Introduction:</b> This disclaimer governs your use of our
              website and web service, which is provided by Accessibly, a
              company based in Canada. By using our website and service, you
              accept this disclaimer in full.
            </p>
            <p>
              2. <b>No Warranties:</b> Our website and service are provided "as
              is" without any warranties of any kind. We make no representations
              or warranties of any kind, express or implied, as to the operation
              of our website and service, or the information, content,
              materials, or products included on our website and service.
            </p>
            <p>
              3. <b>Limitation of Liability:</b> In no event will we be liable
              for any loss or damage including without limitation, indirect or
              consequential loss or damage, or any loss or damage whatsoever
              arising from loss of data or profits arising out of, or in
              connection with, the use of our website and service.
            </p>
            <p>
              4. <b>Exclusions and Limitations:</b> Some jurisdictions do not
              allow the exclusion of certain warranties or the limitation or
              exclusion of liability for incidental or consequential damages.
              Accordingly, some of the above limitations may not apply to you.
            </p>
            <p>
              5. <b>Accuracy of Information:</b> While we take reasonable steps
              to ensure that the information on our website and service is
              accurate, we do not guarantee the accuracy or completeness of the
              information.
            </p>
            <p>
              6. <b>Third-Party Links:</b> Our website and service may contain
              links to third-party websites. We are not responsible for the
              privacy practices or the content of such websites. You should
              review the privacy policy and terms and conditions of each website
              you visit.
            </p>
            <p>
              7. <b>Changes to Disclaimer:</b> We may modify this disclaimer
              from time to time. The revised disclaimer will take effect when
              posted on our website.
            </p>
            <p>
              8. <b>Governing Law:</b> This disclaimer is governed by and
              construed in accordance with the laws of Canada, and you agree to
              submit to the exclusive jurisdiction of the Canadian courts.
            </p>
            <p>
              9. <b>Contact Information:</b> If you have any questions about
              this disclaimer, please contact us at support@accessibly.com.
            </p>
          </Text>
        </ContentWithPaddingXl>
      </Container>
      <Footer />
    </AnimationRevealPage>
  );
};
