/*global chrome*/
import axios from "../defaults/axios_conf";
export const createCheckout = (priceData) => (dispatch) => {
  axios
    .post("/api/v1/payments/createCheckout", priceData)
    .then((res) => {
      console.log("res", res);
      window.location.href = res.data.url;
    })
    .catch((err) => {
      if (err.response) {
        console.log("err response", err.response.data);
      }
    });
};
export const createBillingPortal = (userData) => (dispatch) => {
  console.log("userData", userData);
  axios
    .post("/api/v1/payments/createBillingPortal", userData) //{userId: id} {sessionId: cs_test_a1EefbvvPbRayOLrCG9SwAs45M6GITlQZYLO8zYukrmzjlRxfWR1v7D6tM}
    .then((res) => {
      console.log("res", res);
      window.location.href = res.data.url;
    })
    .catch((err) => {
      if (err.response) {
        console.log("err response", err.response.data);
      }
    });
};
