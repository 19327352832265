import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Header from "components/headers/light.js";
import Pricing from "components/pricing/TwoPlansWithDurationSwitcher.js";
import Testimonial from "components/testimonials/ThreeColumnWithProfileImage.js";
import Footer from "components/footers/FiveColumnWithInputForm.js";
import FAQ from "components/faqs/SingleCol.js";
import { useHistory } from "react-router-dom";

export default () => {
  var pricingSituation = {
    actionHeading: "Get Started",
    actionLink: "/getStarted",
  };
  const history = useHistory();
  window.onpopstate = () => {
    history.push("/");
  };

  return (
    <AnimationRevealPage>
      <Header />
      <Pricing situation={pricingSituation} />
      {/* <Testimonial
        heading="Our Paying Customers"
      /> */}
      {/* <FAQ /> */}
      <Footer />
    </AnimationRevealPage>
  );
};
